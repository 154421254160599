export const users = [
    
]

export const loggedInUser = users[0];

export const loggedInUserSurveys = {
    overdue: [
    
    ],
    current: [
    
    ],
    complete: [
    
    ]
};

export const loggedInUserActiveChats = [
    
];

export const comments = [
    
]