const defaultInstructions = {
  MY_SPACE: {
    title: "A. My Space",
    description: "individual belonging and growth in the organisation.",
  },
  MY_TEAM: {
    title: "B. My Team",
    description: "levels of communication and trust within and between teams.",
  },
  OUR_CULTURE: {
    title: "C. Our Culture",
    description: "for shared values and behaviours for the organisation.",
  },
  OUR_RESULTS: {
    title: "D. Our Results",
    description: "the drive to achieve and exceed targets.",
  },
  OUR_GROWTH: {
    title: "E. Our Growth",
    description: "the capacity to question, innovate and take risks.",
  },
  OUR_PURPOSE: {
    title: "F. Our Purpose",
    description: "alignment to the big picture: strategy and purpose.",
  },
};

export default (behaviour) => {
  const _behaviour = behaviour.replace(/ /g, "_").toUpperCase();
  return defaultInstructions[_behaviour];
};

export const defaultCommentLabels = (surveyType = 'l360', leader= '') =>{
  switch(surveyType){
    case 'l360': return {
      toward: `Which behaviours does the leader show that put you in a TOWARD state, leaving you feeling switched on?`,
      away: `Which behaviours does the leader show that put you in an AWAY state, leaving you feeling switched off?`,
    }
    case 'i360': return {
      toward: `Which behaviours does the team member show that put you in a TOWARD state, leaving you feeling switched on?`,
      away: `Which behaviours does the team member show that put you in an AWAY state, leaving you feeling switched off?`,
    }
    default: return {
      toward: `Which behaviours in the team put you in a TOWARD state, leaving you feeling switched on?`,
      away: `Which behaviours in the team put you in an AWAY state, leaving you feeling switched off?`,
    }
  }
}

export const defaultFeelingRatingLabels = (
  surveyType = "l360",
  self = false
) => {
  switch (surveyType) {
    case "l360": {
      return self
        ? "How do you rate your general working relationships with your team members?"
        : "How do you rate your working relationship with the leader?";
    }
    case "i360": {
      return self
        ? "How do you rate your general working relationships with your team members?"
        : "How do you rate your working relationship with the team member?";
    }
    default:
      return "How do you rate your general working relationships in the team?";
  }
};